import React, { useEffect, useState } from 'react'
import Slider from '@material-ui/core/Slider'
import { Box, Flex } from 'reflexbox'

import * as styles from './RangeControl.module.scss'
import { Props } from './types'

const RangeControl = ({
  name,
  min = 0,
  max = 100,
  defaultMin,
  defaultMax,
  setFilter,
  className,
}: Props): JSX.Element => {
  const [values, setValue] = useState([defaultMin, defaultMax])

  const handleChange = (event: any, newValue: number[]) => {
    setValue(newValue)
  }
  const handleCommit = (event: any, values: number[]) => {
    setFilter({ facet: 'price', value: values.join(',') })
  }

  useEffect(() => {
    setValue([defaultMin, defaultMax])
  }, [defaultMin])

  return (
    <Flex className={className} flexDirection="column">
      <Flex justifyContent="space-between">
        <Box className={styles.price}>
          <Box as="span" className={styles.number}>
            {values[0]}{' '}
          </Box>
          Sek
        </Box>
        <Box className={styles.price}>
          <Box as="span" className={styles.number}>
            {values[1]}{' '}
          </Box>
          Sek
        </Box>
      </Flex>
      <Slider
        min={min}
        max={max}
        value={values}
        onChange={handleChange}
        onChangeCommitted={handleCommit}
        valueLabelDisplay="auto"
        aria-labelledby="range-slider"
      />
    </Flex>
  )
}

export default RangeControl
